import React from "react"
import PlanIcon from '../images/servers-icon.svg'
import VmIcon from '../images/vm-icon.svg'
import CloudIcon from '../images/cloud-icon.svg'
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import './ProductPlan.scss'
import PropTypes from "prop-types"

const ProductPlan = ({ type, btns, item }) => {
  const icon = () => {
    if (type === 'server-plan') {
      return <PlanIcon />;
    }
    if (type === 'vm-plan') {
      return <VmIcon />;
    }
    if (type === 'cloud-plan') {
      return <CloudIcon />;
    }
  }

  const addToCart = (newItem) => {
    let cart = parseInt(localStorage.getItem("cart"));
    cart++;
    localStorage.setItem("cart", cart.toString());
    let cartItems = JSON.parse(localStorage.getItem("cartItems"));

    if ( cartItems.length > 0) {
      const hasItem = cartItems.find( (item) => {
        if (item['name'] === newItem['name']) {
          item['quantity']++;
          return true;
        }
        return false;
      });

      if(!hasItem) {
        cartItems.push(newItem);
      }
    } else {
      cartItems.push(newItem);
    }

    cartItems = JSON.stringify(cartItems);
    localStorage.setItem("cartItems", cartItems);
    const setStorage = new Event('setStorage');
    window.dispatchEvent(setStorage)
  }

  return (
    <div className={`product-plan ${type}`}>
      {icon()}
      <div className="plan-info">
        <p className="plan-title">F 1</p>
        <p className="plan-description">24 Cores, 64GB RAM, 24TB disk</p>
      </div>
      <p className="plan-price">€199</p>
      <button className={`vbtn vbtn--${btns}`} onClick={e => addToCart(item)} ><FormattedMessage id="buttons.add_cart" /></button>
    </div>
  )
};

ProductPlan.propTypes = {
  type: PropTypes.string,
  btns: PropTypes.string,
  item: PropTypes.object
}

export default ProductPlan